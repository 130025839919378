import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { AppConstants, AppHeader } from '@app/_config';
import { Model } from '@app/_common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  get(servicePath: string = '', apiUrl: string = environment.appApi, contentType = 'application/json') {
    return this.g(servicePath, apiUrl, new HttpParams(), contentType);
  }

  getp(servicePath: string = '', httpParams: HttpParams, apiUrl: string = environment.appApi, contentType = 'application/json') {
    return this.g(servicePath, apiUrl, httpParams, contentType);
  }

  download(servicePath: string = '', apiUrl: string = environment.appApi, httpParams: HttpParams, responseType:any = 'arraybuffer'  ) { //blob or arraybuffer
 // console.log({ headers: AppHeader.getHeader('application/json'), params: httpParams , reportProgress:false, responseType:responseType});
    return this.http.get<any>(`${apiUrl}${servicePath}`, { headers: AppHeader.getHeader('application/json'), params: httpParams , reportProgress:false, responseType:responseType} );
  }

  private g(servicePath: string = '', apiUrl: string = environment.appApi, httpParams: HttpParams, contentType: string = 'application/json') {
    const httpOptions = { headers: AppHeader.getHeader(contentType), params: httpParams };
    if (servicePath.startsWith("assets")) {
      return this.http.get<any>(`${servicePath}`,  httpOptions);
    } else {      
      return this.http.get<any>(`${apiUrl}${servicePath}`, httpOptions);
    }
  }

  downloadm(servicePath: string = '', model: Model, responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'arraybuffer') {
    // requestOption.responseType='blob';
    return this.download(servicePath, model.apiUrl, model.p, responseType);
  }

  getm(servicePath: string = '', model: Model, contentType = 'application/json') {
    return this.g(servicePath, model.apiUrl, model.p, contentType);
  }

  savem(servicePath: string = '', model: Model, pkId: any = null, data: any, contentType = 'application/json') {
    if (pkId) {
      return this.put(`${servicePath}/${pkId}`, model, data);
    } else {
      return this.post(`${servicePath}`, model, data);
    }
  }

  post(servicePath: string = '', model: Model, body: any = null, contentType = 'application/json') {
    if (model.getFormData()) { //upload file
      this._preSubmit(model, body);
      return this.http.post(`${model.apiUrl}${servicePath}`, model.getFormData(), { headers: AppHeader.getHeader(null), params: model.p, reportProgress: true, responseType: 'json' });
    }
    else {
      return this.http.post(`${model.apiUrl}${servicePath}`, body ? body : model.value(), { headers: AppHeader.getHeader(contentType), params: model.p });
    }
  }

  put(servicePath: string = '', model: Model, body: any = null, contentType = 'application/json') {
    if (model.getFormData()) { //upload file
      this._preSubmit(model, body);
      return this.http.put(`${model.apiUrl}${servicePath}`, model.getFormData(), { headers: AppHeader.getHeader(null), params: model.p, reportProgress: true, responseType: 'json' });
    }
    else {
      return this.http.put(`${model.apiUrl}${servicePath}`, body ? body : model.value(), { headers: AppHeader.getHeader(contentType), params: model.p });
    }
  }

  private _preSubmit(model:Model, body:any){
    if (body) {
        model.getFormData()?.append("body", body);
      }
      else {
        for (const item in model.form.controls) {
          model.getFormData()?.append(item, model.form.controls[item].value);
        } 
      }
  }
  
  delete(servicePath: string = '', model: Model, body: any = null, contentType = 'application/json') {
    return this.http.delete(`${model.apiUrl}${servicePath}`, { headers: AppHeader.getHeader(contentType), params: model.p, body: body });
  }

  upload(servicePath: string, model: Model, file: File, body: any): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('data', body);
    const req = new HttpRequest('POST', `${model.apiUrl}${servicePath}`, formData, { reportProgress: true, responseType: 'json' });
    return this.http.request(req);
  }

}
