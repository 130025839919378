import { Component, SimpleChanges, Input, OnChanges, Inject } from '@angular/core';
import { ExportService, SysService } from '@app/_common';
@Component({
  selector: 't-export',
  templateUrl: './table-export.component.html',
  styleUrls: ['./table-export.component.scss']
})
export class TableExportComponent implements OnChanges {
  @Input() file: string; // file name
  @Input() rows: any;  // list of datatable values
  @Input() header: any; //header of json
  constructor(private exportService: ExportService, private sysService: SysService) { }

  ngOnChanges(changes: SimpleChanges): void { }

  toCopy() {
    const list = this.toArray();
    const content = this.exportService.toCopyClipboard(this.header, list);
    navigator.clipboard.writeText(content).then().catch(e => console.error(e));
    this.sysService.info('Copied ' + list.length + ' records to clipboard');
    SysService.scrollTo(0);
  }

  toCsv() {
    this.exportService.toCsv(this.file, this.header, this.toArray());
  }

  toXlsx() {
    // console.log(this.header);
    this.exportService.toXlsx(this.file, this.header, this.toArray());
  }

  toPdfTable() {
    this.exportService.toPdfTable(this.file, [this.header], this.toArray());
  }

  toPdfTablePrint() {
    this.exportService.toPdfTablePrint(this.file, [this.header], this.toArray());
  }

  private toArray() {
    const data: any = [];
    let r: any = [];
    let row: any;
    for (const i in this.rows) {
      r = [];
      row = this.rows[i];
      for (const col in this.header) {
        r.push(row[this.header[col]]);
      }
      data.push(r);
    }
    return data;
  }
}

